import { Link } from "react-router-dom";
import Page from "../interfaces/Page";
import UserIcon from "../assets/imgs/user-icon.png";

function Render(): JSX.Element {
  return (
    <div className="base-page" style={{ maxWidth: "1100px" }}>
      <div className="blue-box text-white m-auto mt-custom">
        <h2 className="text-center">La leggenda di Gamberoni 🦐</h2><br />
        <p>In un piccolo villaggio di pescatori, dove il mare era padrone e confidente, le serate si trascorrevano al molo, con il vento che portava sussurri di avventure lontane. Un gruppo di amici, stanchi ma pieni di spirito, si radunava attorno a un lume di candela, cercando un modo per scacciare il silenzio. Una sera, sotto una luna splendente, qualcuno esclamò: "Inventiamo un gioco!"</p><br />
        <p>Le regole erano semplici: alla prima domanda si rispondeva sempre "Gamberoni". Alla seconda, con la risposta della domanda precedente, e così per ogni altra domanda. Presto, il gioco divenne un intreccio di risposte impreviste che li facevano ridere fino alle lacrime, mentre il mare sembrava accompagnare le loro voci con il suo incessante mormorio.</p><br />
        <p>Il gioco, nato per scherzo, si trasformò in una tradizione che scaldava i cuori, una sfida tra amici e un modo per rendere speciale ogni sera. Si racconta che *Gamberoni* fosse più di un passatempo: era un legame con il mare, con le risate che non volevano essere dimenticate. E forse, se passi per quel villaggio, potrai sentire tra le onde un'eco lontana che sussurra: "Gamberoni..."</p>
      </div>

      <div className="d-flex gap-5 justify-content-center mt-5">
        <div className="user-card flex-fill text-center">
          <img src={UserIcon} alt="User Icon" width="200px" />
          <p className="fw-bold">Riccardo Petri</p>
          <p>Dev, Graphic Designer</p>
        </div>
        <div className="user-card flex-fill text-center">
          <img src={UserIcon} alt="User Icon" width="200px" />
          <p className="fw-bold">Pingu-Io</p>
          <p>Dev, Graphic Designer</p>
        </div>
        <div className="user-card flex-fill text-center">
          <img src={UserIcon} alt="User Icon" width="200px" />
          <p className="fw-bold">Davide Nadin</p>
          <p>Dev, Graphic Designer</p>
        </div>
      </div>
    </div>
  );
}

const Home: Page = {
  title: 'Info',
  description: 'Welcome to the Info page!',
  icon: 'bi bi-info-circle-fill',
  path: '/info',
  showInNavbar: true,
  showInHome: false,
  element: Render,
}

export default Home;