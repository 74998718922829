import Question from "../interfaces/Question";
import shuffleSeed from "shuffle-seed";
import GamberoniAudio from "../assets/audio/gamberoni.mp3";
import PopAudio from "../assets/audio/pop.mp3";

abstract class Game {

  protected _seed: string = 'seed';
  protected _isGameStarted: boolean = false;
  protected _isGameOver: boolean = false;

  protected _questions: Question[] = [];
  protected _numberOfQuestions: number = 20;
  protected _currentQuestionIndex: number = 0;

  protected _score: number = 0;
  protected _lives: number = 3;
  protected _time: { minutes: number, seconds: number } = { minutes: 0, seconds: 0 };

  protected _gamberoniSound = new Audio(GamberoniAudio);
  protected _popSound = new Audio(PopAudio);

  protected _showPopUp: boolean = false;
  protected _correctAnswer: boolean | string = false;
  protected _affectedQuestion: string = '';

  constructor(seed: string, questionsPool: Question[], numberOfQuestions: number, lives: number, time: { minutes: number, seconds: number }) {
    this._seed = seed;
    this._numberOfQuestions = numberOfQuestions;
    this._lives = lives;

    this._time = { minutes: time.minutes, seconds: time.seconds }; // todo

    this._questions = shuffleSeed.shuffle(questionsPool, this._seed).slice(0, this._numberOfQuestions).map(q => ({...q, taken: false}));

    this._gamberoniSound.preload = 'auto';
    this._popSound.preload = 'auto';

    console.log(this._questions); // Debug
  }

  public startGame() {
    this._isGameStarted = true;

    this._gamberoniSound.addEventListener('loadeddata', () => {
      this._gamberoniSound.play();
    });
    this._gamberoniSound.load();

    this.nextRound();
  }

  public nextRound(params?: {}) {
    if (params === undefined) {
      this._currentQuestionIndex++;
      return;
    }

    this._popSound.addEventListener('loadeddata', () => {
      this._popSound.play();
    });
    this._popSound.load();
    
    const isWrong = this.checkAnswer(params);

    if (isWrong) {
      this._lives--;

      this._affectedQuestion = this.currentQuestion.question;
      this._correctAnswer = this._questions[this._currentQuestionIndex-1].answer;
      this._showPopUp = true;

      this._currentQuestionIndex = 0;
    } else {
      this._showPopUp = false;

      this._score += (this.currentQuestion.taken ? 0 : 1);
      this.currentQuestion.taken = true;
      this._currentQuestionIndex++;
    }

    if (this._lives === 0) {
      this._isGameOver = true;
    }

    return;
  };

  public updateShowPopUp(params?: {}) {
    this._showPopUp = !this._showPopUp;
  }

  public timesUp(params?: {}) {
    this._isGameOver = true;
  }

  abstract checkAnswer(params: {}): boolean;

  get seed(): string {
    return this._seed;
  }

  get score(): number {
    return this._score;
  }

  get isGameStarted(): boolean {
    return this._isGameStarted;
  }

  get isGameOver(): boolean {
    return this._isGameOver;
  }

  get lives(): number {
    return this._lives;
  }

  get currentQuestionIndex(): number {
    return this._currentQuestionIndex;
  }

  get currentQuestion(): Question {
    return this._questions[this._currentQuestionIndex];
  }

  get showPopUp(): boolean {
    return this._showPopUp;
  }

  get affectedQuestion(): string {
    return this._affectedQuestion;
  }

  get correctAnswer(): any {
    return this._correctAnswer;
  }

  // todo
  get time(): { minutes: number, seconds: number } {
    return this._time;
  }

}

export default Game;