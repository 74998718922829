import React, { useState, useEffect } from 'react';

function Timer(params: { initialMinutes: number, initialSeconds: number, pause: boolean, onTimeUp: () => void }) {
  const [minutes, setMinutes] = useState(params.initialMinutes);
  const [seconds, setSeconds] = useState(params.initialSeconds);

  useEffect(() => {
    const timerId = setInterval(() => {
      if (params.pause) return;
      if (seconds > 0) {
        setSeconds(seconds - 1);
      } else if (minutes > 0) {
        setMinutes(minutes - 1);
        setSeconds(59);
      } else {
        clearInterval(timerId);
        params.onTimeUp();
      }
    }, 1000);

    return () => clearInterval(timerId);
  }, [minutes, seconds, params.onTimeUp]);

  return (
    <div>
      ⌛ {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
    </div>
  );
}

export default Timer;