import { Tooltip } from "react-tooltip";
import LogoXS from "../assets/imgs/logo-xs.png";
import GameMode from "../interfaces/GameMode";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import TextGame from "../classes/TextGame";
import EndScreen from "../components/EndScreen";
import QuestionsJson from "../assets/json/questionsText.json";
import PopUp from "../components/PopUp";
import Timer from "../components/Timer";

function Render(): JSX.Element {
  const [searchParams] = useSearchParams();

  const [game, setGame] = useState<TextGame[]>([new TextGame(searchParams.get("seed") || "seed", QuestionsJson, 20, 3, { minutes: 1, seconds: 30 })]);

  const [userInput, setUserInput] = useState("");
  
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    game[0].nextRound({answer: userInput});
    setGame([game[0]]);
    setUserInput("");
  }

  return (
    game[0].isGameOver ?
    <EndScreen score={game[0].score} gamePath={"/typing-game"} seed={game[0].seed} /> :
    <div className="base-page">
      <div className="d-flex align-items-center flex-column mx-auto" style={{ maxWidth: "500px" }}>
        <img src={LogoXS} width="350px" alt="logo" className="mt-3 mb-4" />

        <div className="d-flex align-items-center gap-3 mb-3 w-100">
          <div className="blue-box w-100 text-center shadow p-3 fs-4 text-white fw-bold">🏆{game[0].score}</div>
          <div className="blue-box w-100 text-center shadow p-3 fs-4 text-white fw-bold">
            <Timer initialMinutes={game[0].time.minutes} initialSeconds={game[0].time.seconds} pause={game[0].currentQuestionIndex === 0 || game[0].showPopUp} onTimeUp={() => {game[0].timesUp(); setGame([game[0]])}} />
          </div>
          <div className="blue-box w-100 text-center shadow p-3 fs-4">&nbsp;{[...new Array(game[0].lives)].map(i => '🦐')}&nbsp;</div>
        </div>

        <div className="blue-box shadow w-100">
          <textarea className="white-box w-100 text-center" rows={2} value={game[0].currentQuestion.question} readOnly style={{ resize: 'none', outline: 'none' }} />
          {
            game[0].currentQuestionIndex === 0 ?
            <button className="orange-btn w-100 mt-3 p-3" onClick={() => {game[0].startGame(); setGame([game[0]])}}>Gamberoni!</button> :
              <div className="input-group mt-3">
                <form onSubmit={handleSubmit} className="d-flex w-100">
                  <input type="text" className="form-control submit-btn-tg" placeholder="Inserisci qui la tua risposta" aria-label="Recipient's username" aria-describedby="button-addon2" value={userInput} onChange={(e) => setUserInput(e.target.value)} />
                  <input type="submit" className="btn green-btn-2 ms-2" id="button-addon2" value="Invio" />
                </form>
              </div>
          }
        </div>

        {
          game[0].showPopUp ? 
          <PopUp affectedQuestion={game[0].affectedQuestion} correctAnswer={game[0].correctAnswer} onClose={() => {game[0].updateShowPopUp(); setGame([game[0]])}} /> :
          null
        }

      </div>

      <div className="position-absolute bottom-0 end-0 mx-5 my-4">
        <a data-tooltip-id="info-tooltip">
          <i className="bi bi-info-circle-fill fs-1"></i>
        </a>
        <Tooltip id="info-tooltip" place='left-start' style={{ width: "300px", backgroundColor: "#55bac2", borderRadius: "15px 15px 9px 15px" }}>
          <ol className="m-4 p-0">
            <li>Prima Domanda: Rispondi sempre con 'gamberoni', indipendentemente dalla domanda.</li>
            <li>Domande Successive: Per ogni domanda successiva alla prima, rispondi basandoti sulla domanda precedente.</li>
            <li>Punteggio: Guadagna punti dando risposte logiche che fanno riferimento correttamente alla domanda precedente.</li>
            <li>Divertiti a giocare a Gamberoni! 🦐</li>
          </ol>
        </Tooltip>
      </div>
    </div>
  );
}

const TypingGame: GameMode = {
  title: 'TypingGame',
  description: 'Welcome to the TypingGame page!',
  icon: '',
  path: '/typing-game',
  showInNavbar: false,
  showInHome: true,
  element: Render,
  gamemodeDescription: 'Gamemode Description',
  gamemodeShortDescription: 'GameMode Short Description',
}

export default TypingGame;